import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';

import { mobile, tablet } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import theme from 'utils/theme';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
  @media ${mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Submit = styled(PrimaryButton)`
  margin-top: 1.5rem;
  width: 100%;
  height: 48px;
  background-color: ${theme.colors};
  font-weight: 600;
  @media ${tablet} {
    margin: 1rem auto;
    width: 100%;
  }
`;

const InputStyled = styled.input`
  display: flex;
  height: 3.5rem;
  padding: 0.80031rem 0.91469rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.75rem;
  background: #f5f5f5;
  outline: none;
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  ::placeholder {
    color: #2a4b4299;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  }
`;

const Error = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem 0;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  placeholder = 'Please enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email.toLowerCase());
  };

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <ContentContainer>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          onChange={handleChange}
          required
        />
        {error ? <Error>{error}</Error> : null}
      </ContentContainer>
      <Submit loading={loading} type="submit" cornerVariant="round">
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
